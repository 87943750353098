import React from 'react'
import { css } from 'glamor'
import { Link } from '@paypalcorp/pp-react'
import getContent from 'pp-react-l10n'
import clientData from 'utils/client-data'
import { getReportLink } from '../../../utils/generate-urls'
import { tracking } from 'lib/analytics'
import Events from 'lib/analytics/event-tags'

const l10n = getContent('pages/seller/preview')

const ReportProfile = css({
  textAlign: 'center',
  display: 'inline-block',
  fontSize: '0.875rem',
  width: '100%',
})

function ReportProfileLink(props) {
  const { isShopsWebExperienceAllowed } = clientData
  const { analyticsData = {} } = props
  return (
    <Link
      onClick={() =>
        tracking(
          isShopsWebExperienceAllowed
            ? Events.public_identity_buyer_item_report_shop_pressed(
                analyticsData,
              )
            : Events.public_identity_profile_report_pressed(),
        )
      }
      className={`${ReportProfile}`}
      href={getReportLink()}
      theme="v2"
      data-test-id="seller-dashboard-preview-report"
    >
      {l10n('reportProfile')}
    </Link>
  )
}
export default ReportProfileLink
