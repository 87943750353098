import clientData from 'utils/client-data'

export function getUserId() {
  const {
    user: { customerId, nsId },
  } = clientData

  if (customerId === 'guest') {
    return nsId
  }

  return customerId
}

export function setKey(userId) {
  return `pp-shops-cart-${userId}`
}

export function updateCartStorage(cartValue) {
  const key = setKey(getUserId())
  localStorage.setItem(key, cartValue)
}

export function getCartStorage() {
  const result = []

  for (
    let storageCounter = 0;
    storageCounter < localStorage.length;
    storageCounter++
  ) {
    const storageItem = localStorage.key(storageCounter)
    if (storageItem.indexOf('pp-shops-cart') >= 0) {
      result.push(storageItem)
    }
  }

  let defaultStorage = {}

  if (result.length > 0) {
    const cartPresent = localStorage.getItem(result[0])
    defaultStorage = cartPresent ? JSON.parse(cartPresent) : {}
  }

  const key = setKey(getUserId())
  return localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key))
    : defaultStorage
}

export function removeCartStorage() {
  const key = setKey(getUserId())
  localStorage.removeItem(key)
}
