import React, { useRef, useState, useEffect } from 'react'
import clientData from '../../../../client/src/utils/client-data'
import { getSendUrl } from '../../../../client/src/utils/generate-urls'
import ProductList from './preview/product-list'
import { get } from 'lodash'
import { css } from 'glamor'
import glamorous from 'glamorous'
import {
  Alert,
  HeadingText,
  BodyText,
  v2grey600,
  grey600,
  grey700,
  v2blue600,
  v2black,
  Button,
  v2blue400,
  Divider,
  white,
  V2MailIcon,
  V2PhoneIcon,
  Link,
} from '@paypalcorp/pp-react'
import SocialLinks from './social/social-links'
import getContent from 'pp-react-l10n'
import { COVER_PHOTO_URLS } from '../../utils/constants'
import { tracking } from 'lib/analytics'
import Events from 'lib/analytics/event-tags'
import {
  phone6PlusPortraitMaxAndSmaller,
  phoneLandscapeMaxAndSmaller,
} from 'lib/styles/media-queries'
import { NEW_ARRIVALS_PREVIEW_TYPE } from './constants/constants'
import ReportProfileLink from './shared/report-profile-link'

const l10n = getContent('pages/seller/preview')
const FadeIn = css.keyframes({ from: { opacity: 0 }, to: { opacity: 1 } })

const paddingWeb = '12%'
const paddingMobile = '20px'

const disabledMod = {
  '& .disabledLink, & .disabledLink:focus, & .disabledLink:active, & .disabledLink:hover': {
    pointerEvents: 'none',
    textDecoration: 'none',
    outline: 'none',
    boxShadow: 'none',
    color: v2blue400,
  },
  '& .disabledBtn, & .disabledBtn:focus, & .disabledBtn:active, & .disabledBtn:hover': {
    border: 'none',
    pointerEvents: 'none',
    textDecoration: 'none',
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: v2blue600,
  },
  '& .disabledBtn::after': {
    border: 'none',
    pointerEvents: 'none',
    textDecoration: 'none',
    outline: 'none',
    boxShadow: 'none',
  },
}

const Container = glamorous.div(props => ({
  width: '100%',
  minWidth: 320,
  maxWidth: props.isSellerDashboardPreview ? 'unset' : 700,
  position: 'relative',
  '& div[role=heading]': {
    color: grey700,
    textAlign: 'left',
  },
  '& div[role=heading] + p': {
    color: grey600,
    margin: '0.5rem 0',
  },
  ...disabledMod,
  [phoneLandscapeMaxAndSmaller]: {
    marginTop: '-30px',
  },
}))

const AlertStyle = css({
  marginBottom: '12px',
})

const BackgroundPhoto = glamorous.div(
  ({ isSellerDashboardPreview, image, pan, fadeIn = true }) => ({
    boxSizing: 'border-box',
    width: '100%',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    '& > div:first-child': {
      borderRadius: '13px 13px 0 0',
      backgroundImage: `url(${encodeURI(image)})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPositionX: '50%',
      backgroundPositionY: `${pan}%`,
      width: '100%',
      paddingBottom: '32%',
      minHeight: 172,
      maxHeight: 293,
      position: 'relative',
      ...(fadeIn && { animation: `${FadeIn} 0.2s` }),
    },
    [phoneLandscapeMaxAndSmaller]: {
      '& > div:first-child': {
        borderRadius: isSellerDashboardPreview ? '13px 13px 0 0' : 0,
      },
    },
  }),
)

const Logo = glamorous.div(({ image, isSellerDashboardPreview }) => ({
  height: '20vw',
  width: '20vw',
  maxHeight: isSellerDashboardPreview ? '85px' : '124px',
  maxWidth: isSellerDashboardPreview ? '85px' : '124px',
  minHeight: '65px',
  minWidth: '65px',
  borderRadius: '50%',
  backgroundImage: `url(${encodeURI(image)})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'absolute',
  left: paddingWeb,
  bottom: 0,
  transform: 'translateY(50%)',
  border: white + ' solid 3px',
  boxSizing: 'content-box',
  overflow: 'hidden',
  [phoneLandscapeMaxAndSmaller]: {
    left: paddingMobile,
  },
}))

const PreviewBlock = glamorous.div({
  borderRadius: 13,
  minHeight: 400,
  padding: `0 ${paddingWeb}`,
  backgroundColor: white,
  [phoneLandscapeMaxAndSmaller]: {
    padding: `0 ${paddingMobile}`,
  },
})

const ProductsBlock = glamorous.div({
  marginTop: '-30px',
})

const ContentBlock = glamorous.div(props => ({
  paddingTop: props.isSellerDashboardPreview ? '200px' : '280px',
  [phone6PlusPortraitMaxAndSmaller]: {
    padding: '3 6%',
  },
  '& hr': {
    margin: '24px 0',
  },
  '& .aboutBlock': {
    color: v2black,
    lineHeight: '1rem',
  },
  '& .aboutBlock + p': {
    color: v2grey600,
    lineHeight: '1rem',
  },
  '& .contacts': {
    '& p, & a': {
      lineHeight: '1rem',
      display: 'flex',
    },
    '& .email span': {
      color: grey700,
    },
    '& p': {
      '& span': {
        marginRight: '0.375rem',
        transform: 'translateY(4px)',
      },
    },
    '& a svg': {
      transform: 'translateY(2px)',
    },
  },
  '& .payButton': {
    textAlign: 'center',
    '& button': {
      padding: 0,
      margin: '40px auto',
      lineHeight: 1,
      width: '100%',
      maxWidth: '350px',
      height: '48px',
    },
  },
  [phoneLandscapeMaxAndSmaller]: {
    paddingTop: '200px',
  },
}))

const AboutDescriptionText = glamorous.p({
  wordWrap: 'break-word',
})

const SellerName = glamorous.h2({
  lineHeight: '1.5rem',
  padding: '10px 0 0 0',
  overflowWrap: 'break-word',
})

const Slug = glamorous.p({
  lineHeight: '1rem',
})

const LocationText = glamorous.p({
  color: grey600,
  lineHeight: '1rem',
  marginBottom: 20,
})

const LocationTextPlaceHolder = glamorous.p({
  clear: 'both',
  width: '100%',
  height: 5,
})

Preview.defaultProps = {
  previewData: {
    paypalmeSlugName: get(clientData, 'sellerProfileDetails.id'),
    payerId: get(clientData, 'recipientSlugDetails.slugDetails.payerId'),
    personalizationSettings: {
      coverPhotoUrl:
        get(
          clientData,
          'sellerProfileDetails.personalization_settings.cover_photo_url',
        ) || COVER_PHOTO_URLS.BLUE.VECTOR,
      coverPhotoVerticalPan: get(
        clientData,
        'sellerProfileDetails.personalization_settings.cover_photo_vertical_pan',
      ),
      description: get(
        clientData,
        'sellerProfileDetails.personalization_settings.description',
      ),
    },
    privacySettings: {
      displayEmailEnabled:
        get(
          clientData,
          'sellerProfileDetails.privacy_settings.display_email_enabled',
        ) || false,
      displayMobilePhoneEnabled:
        get(
          clientData,
          'sellerProfileDetails.privacy_settings.display_mobile_phone_enabled',
        ) || false,
    },
    userInfo: {
      displayEmail: get(
        clientData,
        'sellerProfileDetails.user_info.display_email',
      ),
      displayMobilePhone: get(
        clientData,
        'sellerProfileDetails.user_info.display_mobile_phone',
      ),
      displayAddress: get(
        clientData,
        'sellerProfileDetails.user_info.display_address',
      ),
      instagramUsername: get(
        clientData,
        'sellerProfileDetails.user_info.instagram_username',
      ),
      facebookUrl: get(
        clientData,
        'sellerProfileDetails.user_info.facebook_url',
      ),
      twitterHandle: get(
        clientData,
        'sellerProfileDetails.user_info.twitter_handle',
      ),
      alternateFullName: get(
        clientData,
        'sellerProfileDetails.user_info.alternate_full_name',
      ),
    },
  },
}

function constructPreviewData(sellerProfileState) {
  const state = get(sellerProfileState, 'state') || {}
  return {
    paypalmeSlugName: get(state, 'sellerProfileDetails.id'),
    personalizationSettings: {
      coverPhotoUrl:
        get(
          state,
          'sellerProfileDetails.personalization_settings.cover_photo_url',
        ) || COVER_PHOTO_URLS.BLUE.VECTOR,
      coverPhotoVerticalPan: get(
        state,
        'sellerProfileDetails.personalization_settings.cover_photo_vertical_pan',
      ),
      description: get(
        state,
        'sellerProfileDetails.personalization_settings.description',
      ),
    },
    privacySettings: {
      displayEmailEnabled:
        get(
          state,
          'sellerProfileDetails.privacy_settings.display_email_enabled',
        ) || false,
      displayMobilePhoneEnabled:
        get(
          state,
          'sellerProfileDetails.privacy_settings.display_mobile_phone_enabled',
        ) || false,
    },
    userInfo: {
      displayEmail: get(state, 'sellerProfileDetails.user_info.display_email'),
      displayMobilePhone: get(
        state,
        'sellerProfileDetails.user_info.display_mobile_phone',
      ),
      displayAddress: get(
        state,
        'sellerProfileDetails.user_info.display_address',
      ),
      instagramUsername: get(
        state,
        'sellerProfileDetails.user_info.instagram_username',
      ),
      facebookUrl: get(state, 'sellerProfileDetails.user_info.facebook_url'),
      twitterHandle: get(
        state,
        'sellerProfileDetails.user_info.twitter_handle',
      ),
      alternateFullName: get(
        state,
        'sellerProfileDetails.user_info.alternate_full_name',
      ),
      profilePhotoUrl: get(
        state,
        'sellerProfileDetails.user_info.profile_photo_url',
      ),
    },
  }
}

function Preview(props) {
  const [products, setProducts] = useState([])
  let {
    previewData = {},
    sellerProfileState = {},
    match = {},
    history = () => {},
  } = props

  const isSellerDashboardPreview =
    match?.url.split('/')[3] === 'dashboard' &&
    match?.url.split('/')[4] === 'preview'

  // If seller is in dashboard/preview, then use state instead of clientData
  if (isSellerDashboardPreview) {
    previewData = constructPreviewData(sellerProfileState)
  }

  const { current: isUserLoggedIn } = useRef(clientData.isUserLoggedIn)

  const handlePayButton = () => {
    tracking(Events.public_identity_profile_pay_pressed())
    const payUrl = getSendUrl({
      isLoggedIn: isUserLoggedIn,
      previewData,
    })
    window.location.href = payUrl
  }

  useEffect(() => {
    const productsToShow = get(clientData, 'shop.shopCatalogDetails', []).slice(
      0,
      12,
    )
    //Load inital product list here
    setProducts(productsToShow)
  }, [])

  useEffect(() => {
    tracking(Events.public_identity_profile_screen_shown({}))
  }, [])

  const handleBestSellersProductsClick = () => {
    tracking(Events.public_identity_profile_more_product_pressed({}))
    props.history.push(`/${get(previewData, 'paypalmeSlugName')}/products`)
  }

  const copyLink = `https://paypal.me/${get(
    previewData,
    'paypalmeSlugName',
  )}?country.x=${get(clientData, 'locality.country')}&locale.x=${get(
    clientData,
    'locality.locale',
  )}`

  const previewUserInfoDisplayEmail = get(previewData, 'userInfo.displayEmail')

  return (
    <Container
      data-test-id="seller-dashboard-preview-screen"
      isSellerDashboardPreview={isSellerDashboardPreview}
    >
      {isSellerDashboardPreview ? (
        <Alert
          type="success"
          aria-level="1"
          closeButton={{
            'aria-label': l10n('dismissAlert'),
            onClick: () => {
              history.push(
                `/seller/${get(previewData, 'paypalmeSlugName')}/dashboard`,
              )
            },
          }}
          {...AlertStyle}
        >
          {l10n('description')}
        </Alert>
      ) : null}
      <PreviewBlock data-test-id="seller-dashboard-preview-block">
        <BackgroundPhoto
          isSellerDashboardPreview={isSellerDashboardPreview}
          image={get(previewData, 'personalizationSettings.coverPhotoUrl')}
          pan={get(
            previewData,
            'personalizationSettings.coverPhotoVerticalPan',
          )}
          data-test-id="seller-dashboard-preview-background"
        >
          {/* inner div here for maintaining the aspect ratio*/}
          <div role="banner">
            <Logo
              isSellerDashboardPreview={isSellerDashboardPreview}
              image={get(previewData, 'userInfo.profilePhotoUrl')}
              data-test-id="seller-dashboard-preview-logo"
            />
          </div>
        </BackgroundPhoto>
        <ContentBlock isSellerDashboardPreview={isSellerDashboardPreview}>
          <SellerName role="heading" aria-level="2">
            <HeadingText size="sm">
              {get(previewData, 'userInfo.alternateFullName')}
            </HeadingText>
          </SellerName>

          <Slug>
            <BodyText>@{get(previewData, 'paypalmeSlugName')}</BodyText>
          </Slug>

          <BodyText>
            {get(previewData, 'userInfo.displayAddress') ? (
              <LocationText>
                {get(previewData, 'userInfo.displayAddress')}
              </LocationText>
            ) : (
              <LocationTextPlaceHolder />
            )}
          </BodyText>

          <SocialLinks
            disabledLinks={isSellerDashboardPreview}
            instagramUsername={get(previewData, 'userInfo.instagramUsername')}
            facebookUrl={get(previewData, 'userInfo.facebookUrl')}
            twitterHandle={get(previewData, 'userInfo.twitterHandle')}
            copyLink={copyLink}
            sellerName={get(previewData, 'userInfo.alternateFullName')}
            payerId={get(previewData, 'payerId')}
          />
          <Divider />
          <BodyText className="aboutBlock" strong>
            {l10n('about')}
          </BodyText>
          <AboutDescriptionText>
            <BodyText>
              {get(previewData, 'personalizationSettings.description')}
            </BodyText>
          </AboutDescriptionText>
          <div className="contacts">
            {get(previewData, 'userInfo.displayMobilePhone') &&
            get(previewData, 'privacySettings.displayMobilePhoneEnabled') ? (
              <p>
                <V2PhoneIcon size="xs" />
                <BodyText>
                  {get(previewData, 'userInfo.displayMobilePhone')}
                </BodyText>
              </p>
            ) : null}

            {previewUserInfoDisplayEmail &&
            get(previewData, 'privacySettings.displayEmailEnabled') ? (
              <BodyText>
                <Link
                  className={
                    isSellerDashboardPreview ? 'email disabledLink' : 'email'
                  }
                  onClick={e => {
                    isSellerDashboardPreview
                      ? e.preventDefault()
                      : tracking(Events.public_identity_profile_email_pressed())
                  }}
                  href={`mailto:${previewUserInfoDisplayEmail}`}
                  leftIcon={
                    <V2MailIcon size="xs" style={{ lineHeight: '1.5rem' }} />
                  }
                  theme="v2"
                  data-test-id="seller-dashboard-preview-email"
                >
                  {previewUserInfoDisplayEmail}
                </Link>
              </BodyText>
            ) : null}
          </div>

          <div className="payButton">
            <Button
              className={isSellerDashboardPreview ? 'disabledBtn' : ''}
              disabled={isSellerDashboardPreview ? true : false}
              onClick={e => {
                isSellerDashboardPreview
                  ? e.preventDefault()
                  : handlePayButton()
              }}
              data-test-id="seller-dashboard-preview-pay"
            >
              {l10n('pay')}
            </Button>
          </div>

          {isSellerDashboardPreview ? null : (
            <ProductsBlock>
              {products === null ? (
                <Alert type="error" aria-level="1">
                  {l10n('productsAlert')}
                </Alert>
              ) : (
                <ProductList
                  {...props}
                  heading={l10n(NEW_ARRIVALS_PREVIEW_TYPE)}
                  products={products}
                  showHeading={true}
                  showTopBorder={true}
                  showMoreProductsButton={true}
                  moreProductsHandler={handleBestSellersProductsClick}
                  slug={`${get(previewData, 'paypalmeSlugName')}`}
                  isOnProfilePage={true}
                />
              )}
            </ProductsBlock>
          )}
        </ContentBlock>
      </PreviewBlock>
      {isSellerDashboardPreview ? null : <ReportProfileLink />}
    </Container>
  )
}
export default Preview
