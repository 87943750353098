/* eslint-disable react/jsx-key */
// @flow

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AnimatedSwitchPagination, ShopsLayout } from './async-loading'
import { ThemeProvider } from '@paypalcorp/pp-react'
import DummyTempComponent from './screens/shops-buyer'

import ShopsBuyerStateContainer from './screens/shops-buyer/container'

// please make sure you also update the routes on node level in server/src/routes/index.js
const overpanelViews = [
  '/buy/success',
  '/:shopHandle/chat',
  '/manage/orders',
  '/manage/orders/:orderId/status',
  '/manage/lists',
  '/buy/error',
]

export default () => {
  return (
    <Route exact path={overpanelViews}>
      <ShopsLayout>
        <AnimatedSwitchPagination>
          {/*
           * Seller's Product detail page       - /:shopHandle/:productId/details
           * Order success page                 - /buy/success
           * Chat with Seller                   - /:shopHandle/chat
           * See/manage all orders              - /manage/orders
           * Check order status/Cancel order    - /manage/orders/:orderId/status
           * Manage favorites and other lists   - /manage/lists
           * Error page for shops               - /seller/error
           */}
          <ShopsBuyerRoute
            exact
            path="/buy/success"
            component={DummyTempComponent}
          />
          <ShopsBuyerRoute
            exact
            path="/:shopHandle/chat"
            component={DummyTempComponent}
          />
          <ShopsBuyerRoute
            exact
            path="/manage/orders"
            component={DummyTempComponent}
          />
          <ShopsBuyerRoute
            exact
            path="/manage/orders/:orderId/status"
            component={DummyTempComponent}
          />
          <ShopsBuyerRoute
            exact
            path="/manage/lists"
            component={DummyTempComponent}
          />
          <Route path="/buy/error">
            <Redirect
              to={{
                pathname: '/seller/error',
                state: {
                  error: {
                    illustrationKey: 'critical',
                    headerKey: 'generic',
                    subHeaderKey: null,
                    ctaButtonKey: 'ineligible',
                  },
                },
              }}
            />
          </Route>
        </AnimatedSwitchPagination>
      </ShopsLayout>
    </Route>
  )
}

type ReactComponent = () => React.Element | React.Component

export function ShopsBuyerRoute({
  component: Comp,
  customProps = {},
  ...props
}: {
  component: ReactComponent,
}) {
  return (
    <Route
      exact
      render={routerProps => (
        <>
          <ThemeProvider theme="v2">
            <ShopsBuyerStateContainer
              {...routerProps}
              body={<Comp {...routerProps} {...customProps} />}
              renderErrorScreen={<DummyTempComponent />}
            />
          </ThemeProvider>
        </>
      )}
      {...props}
    />
  )
}
