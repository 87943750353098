// @flow

import React from 'react'
import { tracking } from 'lib/analytics'
import PropTypes from 'prop-types'
import glamorous from 'glamorous'
import { css } from 'glamor'
import {
  v2black,
  v2neutral200,
  v2grey600,
  Link,
  BodyText,
  V2ImageIcon,
} from '@paypalcorp/pp-react'
import { getFormattedAmount } from '../amount-utils'
import Events from 'lib/analytics/event-tags'
import { smallAndSmaller } from 'lib/styles/media-queries'

const FadeIn = css.keyframes({ from: { opacity: 0 }, to: { opacity: 1 } })

const Container = glamorous.div(({ photoHeightWeb, photoHeightMobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
  color: v2black,
  marginBottom: '20px',
  width: photoHeightWeb,
  '& .productText': {
    color: 'inherit',
    fontWeight: 'normal',
    fontSize: '0.857rem',
    fontFamily: 'inherit',
    textAlign: 'left',
    lineHeight: '1.25',
    marginBottom: '10px',
  },
  '& .productName': {
    display: '-webkit-box',
    verticalAlign: 'middle',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  },
  '& .productPrice': {
    marginBottom: '10px',
    lineHeight: '1',
  },
  [smallAndSmaller]: {
    width: photoHeightMobile,
  },
}))

const ProductImage = glamorous.img(({ photoHeightWeb, photoHeightMobile }) => ({
  width: photoHeightWeb,
  height: photoHeightWeb,
  borderRadius: '15px',
  marginBottom: '5px',
  animation: `${FadeIn} 0.5s`,
  objectFit: 'cover',
  [smallAndSmaller]: {
    height: photoHeightMobile,
    width: photoHeightMobile,
  },
}))

const NullImage = glamorous.div({
  width: '128px',
  height: '128px',
  background: v2neutral200,
  color: v2grey600,
  borderRadius: '12px',
  marginBottom: '5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

ProductMiniView.defaultProps = {
  photoUrl: 'https://www.paypalobjects.com/profiles/cover/green.jpg',
  price: {},
  photoHeightWeb: '120px',
  photoHeightMobile: '160px',
}

ProductMiniView.propTypes = {
  photoUrls: PropTypes.exact({
    imageDetails: PropTypes.exact({
      referenceUrl: PropTypes.string,
    }),
  }),
  name: PropTypes.string,
  id: PropTypes.string,
  price: PropTypes.exact({
    currencyCode: PropTypes.string,
    value: PropTypes.string,
  }),
}

function ProductMiniView(props) {
  const {
    photoHeightWeb,
    photoHeightMobile,
    slug,
    shopsBuyerState = {},
    product: { id, name, price, availableQuantity, listingImages } = {},
    isOnProfilePage,
  } = props

  const photoUrl =
    listingImages && listingImages[0]?.imageUrl
      ? listingImages[0]?.imageUrl
      : null

  const handleProductClick = () => {
    const eventName = isOnProfilePage
      ? 'public_identity_profile_item_pressed'
      : 'public_identity_shop_product_list_item_pressed'
    tracking(
      Events[eventName]({
        item_id: id,
        count: availableQuantity,
      }),
    )
    shopsBuyerState.setCurrentProductId(id)
    props.history.push(`/${slug}/products/${id}`)
  }

  return (
    <Container
      photoHeightWeb={photoHeightWeb}
      photoHeightMobile={photoHeightMobile}
    >
      <Link
        onClick={() => {
          handleProductClick()
        }}
      >
        {photoUrl === null ? (
          <NullImage>
            <V2ImageIcon />
          </NullImage>
        ) : (
          <ProductImage
            photoHeightWeb={photoHeightWeb}
            photoHeightMobile={photoHeightMobile}
            alt={name}
            src={photoUrl}
          />
        )}
      </Link>

      <Link
        onClick={() => {
          handleProductClick()
        }}
        className="productName productText"
      >
        <BodyText>{name}</BodyText>
      </Link>

      <Link
        className="productText"
        onClick={() => {
          handleProductClick()
        }}
      >
        <BodyText className="productPrice" strong>
          {getFormattedAmount({
            amount: price.value,
            currencyCode: price.currencyCode,
          })}
        </BodyText>
      </Link>
    </Container>
  )
}

export default ProductMiniView
