// @flow

import clientData from 'utils/client-data'
import griffinNumber from 'griffin-paypal/number'

const { griffinMetadata } = clientData
const griffinNumberHandler = griffinNumber.createHandler(griffinMetadata)

function getAmountFieldInfo(): {
  currencySymbolMap: CurrencySymbolMap,
  currencyDecimal: CurrencyDecimal,
} {
  const {
    numbers: { currencies },
    numbers,
  } = griffinMetadata.cldr.main[griffinMetadata.localityKey]

  const currencySymbolMap = Object.keys(currencies).reduce((map, code) => {
    map[code] = currencies[code].symbol
    return map
  }, {})
  const { decimal: currencyDecimal } = numbers['symbols-numberSystem-latn']
  return { currencySymbolMap, currencyDecimal }
}

function getFormattedValue({
  amount = '0',
  currencyCode = 'USD',
}: {
  amount: string,
  currencyCode: string,
}): string {
  const parts = griffinNumberHandler.formatCurrency(
    {
      value: normalizeNumber(amount),
      currency: currencyCode,
    },
    griffinNumberHandler.CURRENCY_FORMAT_SYMBOLISOCURRENCY.parts(),
  )
  return parts.find(p => p.type === 'value').value
}

function normalizeNumber(numberString: string): number {
  return Number(toCanonicalNumber(numberString))
}

// this is the amount that our APIs expect (a cononicle)
function getFormattedAmount({
  amount = '0',
  currencyCode = 'USD',
  symbolIsoCurrency = false,
}: {
  amount: string,
  currencyCode: string,
}): string {
  const options = symbolIsoCurrency
    ? griffinNumberHandler.CURRENCY_FORMAT_SYMBOLISOCURRENCY
    : griffinNumberHandler.CURRENCY_FORMAT
  return griffinNumberHandler.formatCurrency(
    {
      value: normalizeNumber(amount),
      currency: currencyCode,
    },
    options,
  )
}

function toCanonicalNumber(numberString) {
  const [otherNumbers, decimalGroup = '0'] = numberString.split(
    isCommaDecimal(numberString) ? ',' : '.',
  )
  return `${otherNumbers.split(/[.,]/).join('')}.${decimalGroup}`
}

// This determines whether the decimal of the string is using comma-notation
// based on whether one of the following is true:
// 1. The last three characters in the string includes `,` (12,34)
// 2. The last three characters in the string does NOT include `.`,
//    but the rest of the number DOES include `.` (123.456)
function isCommaDecimal(numberString) {
  const lastThree = numberString.slice(-3)
  return (
    lastThree.includes(',') ||
    (!lastThree.includes('.') && numberString.includes('.'))
  )
}

export {
  toCanonicalNumber,
  getFormattedValue,
  getAmountFieldInfo,
  normalizeNumber,
  getFormattedAmount,
}
