import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  v2blue400,
  v2grey600,
  Divider,
  HeadingText,
} from '@paypalcorp/pp-react'
import { get } from 'lodash'
import getContent from 'pp-react-l10n'
import glamorous from 'glamorous'

import {
  smallAndSmaller,
  phoneLandscapeMaxAndSmaller,
} from 'lib/styles/media-queries'
import clientData from 'utils/client-data'
import ProductMiniView from '../../shared/components/product-mini-view'

const l10n = getContent('pages/seller/preview')
const isShopsWebExperienceAllowed = get(
  clientData,
  'isShopsWebExperienceAllowed',
  false,
)

const Container = glamorous.div(() => ({
  fontFamily: 'PayPalSansBig-Regular,"Helvetica Neue",Arial,sans-serif',
  paddingBottom: '20px',
  margin: '0 0 15px 0',
  '& ul': {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: '0',
    margin: '0',
    '& li': {
      flexGrow: '1',
      width: '25%',
      maxWidth: '25%',
      display: 'flex',
      justifyContent: 'center',
      [smallAndSmaller]: {
        width: '33.33%',
        maxWidth: '33.33%',
      },
      [phoneLandscapeMaxAndSmaller]: {
        width: '50%',
        maxWidth: '50%',
      },
    },
  },
}))

const PreviewProductHeading = glamorous.h3({
  color: v2grey600,
  fontWeight: 'normal',
  fontSize: '0.857rem',
  lineHeight: '1rem',
  margin: '0 0 20px',
})

const MoreProductsLink = glamorous.div({
  textAlign: 'center',
  margin: '0 0 16px',
  '& button, & button:hover, & button:focus, & button:active, & button:focus:after': {
    margin: '0 auto',
    fontSize: '0.857rem',
    lineHeight: '1rem',
    color: v2blue400,
    background: 'none',
    border: '0',
    padding: '0',
    fontWeight: 'bold',
    boxShadow: 'none',
  },
})

ProductList.defaultProps = {
  products: [],
  productLimitForShowMoreButton: 9,
  showHeading: false,
  showMoreProductsButton: false,
  showTopBorder: false,
}

ProductList.propTypes = {
  heading: PropTypes.string,
  moreProductsHandler: PropTypes.func,
  productLimitForShowMoreButton: PropTypes.number,
  products: PropTypes.array,
  showHeading: PropTypes.bool,
  showMoreProductsButton: PropTypes.bool,
  showTopBorder: PropTypes.bool,
}

function ProductList(props) {
  const {
    heading,
    moreProductsHandler,
    productLimitForShowMoreButton,
    products,
    showHeading,
    showMoreProductsButton,
    showTopBorder,
    isOnProfilePage = false,
  } = props
  const { length } = products

  const renderMoreProductsButton = () => {
    if (length >= productLimitForShowMoreButton && showMoreProductsButton) {
      return (
        <MoreProductsLink>
          <Button onClick={moreProductsHandler}>{l10n('moreProducts')}</Button>
        </MoreProductsLink>
      )
    }

    return null
  }

  if (length > 0 && isShopsWebExperienceAllowed) {
    return (
      <Container>
        {showTopBorder ? <Divider /> : null}

        {showHeading ? (
          <PreviewProductHeading>{heading}</PreviewProductHeading>
        ) : null}

        <ul>
          {products.map(product => (
            <li key={product.id}>
              <ProductMiniView {...props} product={product} />
            </li>
          ))}
        </ul>

        {renderMoreProductsButton()}
      </Container>
    )
  } else if (!isOnProfilePage) {
    return (
      <Container>
        <HeadingText size="sm">
          Oops! Looks like there are no products listed.
        </HeadingText>
      </Container>
    )
  }

  return null
}

export default ProductList
