/* eslint-disable i18n-scan/no-inline-concatenation */
// @flow

import React, { Fragment } from 'react'
import { withRouter } from 'react-router'
import { Route, Switch, Redirect } from 'react-router-dom'
import type { RouterHistory } from 'react-router'
import clientData from 'utils/client-data'
import { get } from 'lodash'
import Events from 'lib/analytics/event-tags'

import {
  AnimatedSwitchPagination,
  SimpleLayout,
  SessionTimer,
  MainComponent,
  ProfileDetails,
  SlugProfile,
  Grab,
  Taken,
  Success,
  Picture,
  SettingsContainer,
  MyProfileSettings,
  ProfileSettings,
  Username,
  TurnOffConfirmation,
  About,
  PersonalMessage,
  Location,
  CoverSelect,
  CoverPhoto,
  CoverPan,
  ProfileReview,
  LandingPage,
  CreateUserNameForPersonalProfile,
  CreateForExistingUser,
  Preference,
  GrabLandingPage,
  Products,
  ProductDetail,
  ReviewCart,
} from './async-loading'

import ErrorScreen from './screens/error'
import SellerRoutes from './seller-routes'
import VisaPlusRoutes from './visa-plus-routes'
import ShopsBuyerRoutes, { ShopsBuyerRoute } from './shops-buyer-routes'

const overpanelViews = [
  'picture',
  'grab',
  'settings',
  'taken',
  'error',
  'success',
  'location',
  'cover',
  'cover/select',
  'cover/pan',
  'status',
  'confirm',
  'username',
  'about',
  'personalmessage',
  'review',
  'welcome',
  'profile',
  'create',
  'createusername',
  'preference',
  'landing',
  'session-timeout',
]

const isProfileTreatment = get(clientData, 'isProfileTreatment', false)
const isEditHandleTreatment = get(clientData, 'isEditHandleTreatment', false)
const isShopsWebExperienceAllowed = get(
  clientData,
  'isShopsWebExperienceAllowed',
  false,
)
const sellerRoutes = SellerRoutes()
const shopsBuyerRoutes = ShopsBuyerRoutes()
const isMultiCartExperienceEnabled = get(
  clientData,
  'isMultiCartExperienceEnabled',
  false,
)
const visaPlusRoutes = VisaPlusRoutes()

type AppProps = {
  history: RouterHistory,
}

class App extends React.Component<AppProps> {
  componentDidMount() {
    const isUserLoggedIn = get(clientData, 'isUserLoggedIn')
    const intent = get(clientData, 'recipientSlugDetails.slugDetails.intent')
    const shopId = get(clientData, 'recipientSlugDetails.slugDetails.shopId')
    const accountType = get(clientData, 'user.userAccountType') || ''

    const currentPath = this.props.history.location.pathname
    if (intent === 'COMMERCE' || currentPath.split('/')[1] === 'seller') {
      Events.updateCommonProps({
        profile_type: 'seller',
        store_id: shopId,
        acct_type: isUserLoggedIn ? accountType.toLowerCase() : 'guest',
      })
    } else {
      Events.updateCommonProps({
        profile_type: 'personal',
      })
    }
  }

  render() {
    return (
      <div>
        <SessionTimer />

        <Switch>
          {visaPlusRoutes}
          {sellerRoutes}
          {shopsBuyerRoutes}
          {/* ANIMATED ROUTING WITHIN OVERPANEL DESIGN */}
          <Route exact path={`/my/(${overpanelViews.join('|')})`}>
            <SimpleLayout>
              <AnimatedSwitchPagination>
                {/* GRAB */}
                <GrabRoute exact path="/my/grab" component={Grab} />
                <GrabRoute exact path="/my/success" component={Success} />
                <GrabRoute exact path="/my/picture" component={Picture} />
                <GrabRoute exact path="/my/taken" component={Taken} />
                <GrabRoute
                  exact
                  path="/my/landing"
                  component={GrabLandingPage}
                />
                <GrabRoute
                  exact
                  path="/my/create"
                  component={CreateForExistingUser}
                />
                {/* Personal Profile Handle */}
                <GrabRoute
                  exact
                  path="/my/createusername"
                  component={CreateUserNameForPersonalProfile}
                />

                {/* SETTINGS */}
                <SettingsRoute
                  exact
                  path="/my/welcome"
                  component={LandingPage}
                />
                <SettingsRoute
                  exact
                  path="/my/settings"
                  component={
                    isProfileTreatment ? ProfileSettings : MyProfileSettings
                  }
                />
                <SettingsRoute
                  exact
                  path="/my/confirm"
                  component={TurnOffConfirmation}
                />
                {(isProfileTreatment || isEditHandleTreatment) && (
                  <SettingsRoute
                    exact
                    path="/my/username"
                    component={Username}
                  />
                )}
                {isProfileTreatment ? (
                  <SettingsRoute exact path="/my/about" component={About} />
                ) : (
                  <SettingsRoute
                    exact
                    path="/my/personalmessage"
                    component={PersonalMessage}
                  />
                )}
                <SettingsRoute exact path="/my/location" component={Location} />
                {!isProfileTreatment && (
                  <SettingsRoute
                    exact
                    path="/my/cover"
                    component={CoverPhoto}
                  />
                )}
                {isProfileTreatment && (
                  <SettingsRoute
                    exact
                    path="/my/cover/select"
                    component={CoverSelect}
                  />
                )}
                {isProfileTreatment && (
                  <SettingsRoute
                    exact
                    path="/my/cover/pan"
                    component={CoverPan}
                  />
                )}
                {!isProfileTreatment && (
                  <SettingsRoute
                    exact
                    path="/my/review"
                    component={ProfileReview}
                  />
                )}
                <SettingsRoute
                  exact
                  path="/my/profile"
                  component={ProfileDetails}
                />
                {!isProfileTreatment && (
                  <SettingsRoute
                    exact
                    path="/my/preference"
                    component={Preference}
                  />
                )}

                {/* ERROR */}
                <Route exact path="/my/error" component={ErrorScreen} />

                {/* UNMATCHED CATCH-ALL ROUTES */}
                <Route path="/my">
                  <Redirect to="/my/profile" />
                </Route>
                <Route path="/grab">
                  <Redirect to="/my/grab" />
                </Route>
              </AnimatedSwitchPagination>
            </SimpleLayout>
          </Route>

          <Route>
            <MainComponent>
              <Switch>
                {isShopsWebExperienceAllowed && (
                  <ShopsBuyerRoute
                    exact
                    path={'/:slug/products'}
                    component={Products}
                  />
                )}

                {isShopsWebExperienceAllowed && (
                  <ShopsBuyerRoute
                    exact
                    path={'/:slug/products/:productId'}
                    component={ProductDetail}
                  />
                )}

                {isShopsWebExperienceAllowed &&
                  isMultiCartExperienceEnabled && (
                    <ShopsBuyerRoute
                      exact
                      path={'/:slug/buy/cart'}
                      component={ReviewCart}
                    />
                  )}

                {isShopsWebExperienceAllowed ? (
                  <ShopsBuyerRoute
                    exact
                    path={'/:slug(\\w{2,20})/:amountInfo?'}
                    component={SlugProfile}
                  />
                ) : (
                  <Route
                    exact
                    path={'/:slug(\\w{2,20})/:amountInfo?'}
                    component={SlugProfile}
                  />
                )}
              </Switch>
            </MainComponent>
          </Route>

          <Redirect to="/" />
        </Switch>
      </div>
    )
  }
}

type ReactComponent = () => React.Element | React.Component

function SettingsRoute({
  component: Comp,
  ...props
}: {
  component: ReactComponent,
}) {
  return (
    <Route
      exact
      render={routerProps => (
        <Fragment>
          <SettingsContainer
            {...routerProps}
            body={<Comp {...routerProps} />}
            slugDetails={clientData.slugDetails}
            renderErrorScreen={<ErrorScreen />}
          />
        </Fragment>
      )}
      {...props}
    />
  )
}

function GrabRoute({ component: Comp }: { component: ReactComponent }) {
  return <Route exact render={routerProps => <Comp {...routerProps} />} />
}

export default withRouter(App)
