import { Container } from 'unstated'
import update from 'immutability-helper'
import { client } from 'utils/client'
import clientData from 'utils/client-data'
import { get } from 'lodash'
import { tracking } from 'lib/analytics'
import Events from 'lib/analytics/event-tags'
import { singleProductDetailsQuery } from '../../gql/query/single-product'

import { updateCartStorage, getCartStorage } from './cart/utils/local-storage'

class ShopsBuyerState extends Container {
  state = {
    orderHistory: {
      // contains objects for orders from shop1, shop2 and so on
      // also order status?
    },
    catalogListing: get(clientData, 'shop.shopCatalogDetails'),
    singleProduct: {},
    lists: {
      // manage favorites and other lists
      // 'favorites': [{ product1/shop1 }, { product2/shop2 }]
    },
    cart: getCartStorage(),
    currentProductId: '',
  }

  setCurrentProductId = id => {
    this.setState({
      ...this.state,
      currentProductId: id,
    })
  }

  getSingleProduct = async (productId, shopId, slug) => {
    const variables = {
      listingId: productId,
      shopId,
      profileId: slug,
    }

    let product = {}

    try {
      product = await client.request(singleProductDetailsQuery, {
        input: variables,
      })
    } catch (e) {
      return e.response.status
    }

    if (product.productDetails === null) {
      return null
    } else {
      this.setState({
        singleProduct: product.productDetails,
        ...this.state,
      })
      return product.productDetails
    }
  }

  addOrUpdateItemInCart = async (shopHandle, product) => {
    let newState
    if (shopHandle in this.state.cart) {
      // If product already exists in cart, only update quantity in that case
      const productIndex = this.state.cart[shopHandle].findIndex(
        item => item.productId === product.productId,
      )
      if (this.state.cart[shopHandle][productIndex]) {
        newState = update(this.state, {
          cart: {
            [shopHandle]: {
              [productIndex]: {
                quantity: { $set: product.quantity },
              },
            },
          },
        })
      } else {
        // If user already has some products but this product doesn't exist
        newState = update(this.state, {
          cart: {
            [shopHandle]: {
              $push: [product],
            },
          },
        })
      }
    } else {
      // If cart is empty for this shop handle
      newState = update(this.state, {
        cart: {
          $set: {
            [shopHandle]: [product],
          },
        },
      })
    }

    if (newState) {
      await this.setState(newState)
      updateCartStorage(JSON.stringify(this.state.cart))

      tracking(
        Events.public_identity_buyer_item_cart_badge_updated({
          badge_num: this.getTotalCartCount(),
          item_id: product?.productId,
          item_cnt: product?.quantity,
          status:
            product?.availableQuantity > 0 ||
            product?.availableQuantity === null
              ? 'in stock'
              : 'out of stock',
        }),
      )
    }
  }

  deleteItemFromCart = async (shopHandle, productId) => {
    if (shopHandle in this.state.cart) {
      const productIndex = this.state.cart[shopHandle].findIndex(
        item => item.productId === productId,
      )

      // Remove the item from shop
      const newState = update(this.state, {
        cart: {
          [shopHandle]: {
            $splice: [[productIndex, 1]],
          },
        },
      })

      // If there are no items in the cart for this shop, remove the shop from the cart
      const updatedCart = {}
      for (const key in newState.cart) {
        if (newState.cart[key].length !== 0) {
          updatedCart[key] = newState.cart[key]
        }
      }

      const updatedState = {
        ...newState,
        cart: updatedCart,
      }
      await this.setState(updatedState)
      updateCartStorage(JSON.stringify(this.state.cart))
    }
  }

  getCartCountForShop = shopHandle => {
    if (shopHandle in this.state.cart && this.state.cart[shopHandle]) {
      return this.state.cart[shopHandle].reduce(
        (sum, item) => sum + parseInt(item.quantity),
        0,
      )
    }
    return 0
  }

  getCountForShopProductId = (shopHandle, productId) => {
    if (shopHandle in this.state.cart && this.state.cart[shopHandle]) {
      const product = this.state.cart[shopHandle].find(
        item => item.productId === productId,
      )

      return product && product.quantity ? product.quantity : 0
    }

    return 0
  }

  getTotalCartCount = () => {
    let total = 0
    for (const shopHandle in this.state.cart) {
      total += this.getCartCountForShop(shopHandle)
    }
    return total
  }
}

export default ShopsBuyerState
