// Graphql call to the node layer
export const singleProductDetailsQuery = `
    query singleProductFromShop ($input: ShopQueryArgs) {
        productDetails (
            passedIn: $input
        )
        {
            id
            name
            description
            price {
                currencyCode
                value
            }
            availableQuantity
            listingStatus
            shippingDetails {
                type
                cost {
                    currencyCode
                    value
                }
            }
            listingImages {
                imageType
                imageUrl
            }
            createTime
            updateTime
        }
    }
`
